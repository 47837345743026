<template>
  <div>
    <b-container
      v-if="isLoaded"
      fluid
      class="px-0"
    >
      <b-row class="mb-2 px-1">
        <div class="d-flex w-100 align-items-start flex-wrap">
          <div class="align-self-start d-flex flex-row">
            <div class="header-right-label d-flex align-items-center mr-2">
              <b-card-title
                v-if="order.shipmentSeqId"
                class="m-0"
              >
                Shipment ID: {{ order.shipmentSeqId }}
              </b-card-title>
              <b-card-title
                v-else
                class="m-0"
              >
                Order ID: {{ order.orderSeqId }}
              </b-card-title>
            </div>
            <div class="header-left-actions d-flex">
              <split-action-button
                v-if="!isShipment"
                label="Download Order"
                :icon="DownloadCloudIcon"
                :disable-button="!canShowDownloadOrderButton"
                :hide-dropdown-options="!canShowOrderDropdownOptions"
                dropdown-menu-width="223px"
                @click="$bvModal.show(DOWNLOAD_MODAL_ID)"
              >
                <b-dropdown-item
                  v-if="canShowImportOrderContentBtn"
                  @click="$bvModal.show('upload-Order')"
                >
                  <b-img
                    class="btn-svg-icon"
                    :src="ImportContentIcon"
                    alt="import content"
                  />
                  Import Content
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canShowExportOrderContentBtn"
                  @click="exportOrderProducts"
                >
                  <b-img
                    class="btn-svg-icon"
                    :src="ExportContentIcon"
                    alt="Export content"
                  />
                  Export Content
                </b-dropdown-item>
              </split-action-button>
              <shipment-action
                v-if="isShipment"
                :shipment="order"
                @load-shipment="$emit('load-shipment')"
              />
              <order-upload-invoice
                v-if="
                  hasOrderConfirmed && !canShowOrderDropdownOptions && isBrand
                "
                class="ml-1"
                :order="order"
                :load-order="loadOrder"
              />
            </div>
          </div>
          <div class="ml-auto">
            <b-button
              v-if="
                order.status === ORDER_STATUS.CONFIRMED &&
                  order.isSelfManagedOrder &&
                  !shipmentOrder &&
                  isBrand
              "
              variant="danger"
              @click="cancelOrderByBrand(false)"
            >
              Cancel order
            </b-button>
            <order-actions
              v-else
              :order="order"
              :original-order="orderData"
              :total-units="totalUnits"
              :modify-using-import="modifyUsingImport"
              :is-direct-brand="isDirectBrand"
              :load-order="loadOrder"
              @enable-modify-changes="enableModifyProducts"
              @revert-modified-changes="revertModifiedChanges"
              @update-order="updateOrder"
            />
          </div>
          <div
            v-if="shipmentOrder && !order.shipmentId"
            class="ml-auto"
          >
            <b-button
              class="mr-1 font-weight-bolder"
              variant="outline-info"
              @click="$emit('cancel-create-shipment')"
            >
              Cancel
            </b-button>
            <b-button
              class="ml-1 font-weight-bolder"
              variant="info"
              :disabled="isShipping"
              @click="$emit('create-shipment')"
            >
              {{ isShipping ? 'Loading...' : 'Create Shipment' }}
            </b-button>
          </div>
        </div>
      </b-row>
      <b-row class="p-0 match-height order-detail">
        <!-- Order log -->
        <b-col lg="4">
          <order-logs :order="order" />
        </b-col>
        <!-- End Order log -->
        <!-- Order info-->
        <b-col lg="4">
          <order-summary
            :order="order"
            @load-order="loadOrder"
          />
        </b-col>
        <!-- End Order info -->
        <!-- Order address -->
        <b-col lg="4">
          <order-addresses :order="order" />
        </b-col>
        <!-- End Order address -->
      </b-row>
      <!-- order products filter -->
      <b-row
        v-if="order.products && order.products.length"
        class="mb-1 order-products-filter"
      >
        <b-col cols="12">
          <order-products-filter
            :order-products="order.products"
            @on-apply-filter="onAppliedFilter"
          />
        </b-col>
      </b-row>
      <!-- order products -->
      <b-row>
        <b-col cols="12">
          <div class="product-checkout mt-0 list-view d-block">
            <b-row>
              <order-products
                :key="reloadkey"
                class="col-md-12"
                :order="order"
                :order-products="
                  isProductsFilterApplied
                    ? filteredOrderProducts
                    : order.products
                "
                :is-shipment="shipmentOrder ? true : false"
                :is-modify-enabled="isModifyEnabled"
                :is-filter-applied="isProductsFilterApplied"
                @remove-product="removeOrderProduct"
                @ship-now="$emit('ship-now', $event, orderData.products.length)"
              />
            </b-row>
          </div>
        </b-col>
      </b-row>
      <!-- end order products -->
      <b-modal
        id="upload-Order"
        centered
        title="Upload Order"
        hide-footer
      >
        <div>
          <b-form>
            <b-form-group
              label="Upload the Order products file"
              label-for="import-Order"
            >
              <b-form-file
                id="import-Order"
                v-model="orderUploadFile"
                accept=".xlsx"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
            <b-button
              class="float-right mt-2 mt-1"
              variant="primary"
              :disabled="uploading"
              @click="syncOrderProducts"
            >
              {{ uploading ? 'Uploading...' : 'Upload' }}
            </b-button>
          </b-form>
        </div>
      </b-modal>
      <b-modal
        v-if="isBrand"
        id="brand-cancel-order"
        ok-variant="danger"
        ok-title="Confirm"
        variant="danger"
        cancel-title="Cancel"
        modal-class="modal-danger"
        centered
        title="Cancel order"
        @ok="cancelOrderByBrand(true)"
      >
        <b-card-text> This order contains shipments. </b-card-text>
        <ul v-if="cancellingShipments">
          <li
            v-for="(item, index) in cancellingShipments"
            :key="index"
          >
            {{ item.shipmentSeqId }} - {{ SHIPMENT_TEXT[item.status] }}
          </li>
        </ul>
        <b-card-text> Proceed with the cancellation? </b-card-text>
      </b-modal>
      <!-- modal for download order products -->
      <div>
        <download-modal
          :modal-id="DOWNLOAD_MODAL_ID"
          :options="downloadOption.order"
          :is-downloading="isDownloading"
          @on-click-download="downloadOrderProducts"
        />
      </div>
    </b-container>
    <!-- loading when fetching -->
    <template v-else>
      <b-card class="text-center p-4">
        <b-spinner />
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BButton,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormFile,
  BSpinner,
  BImg,
  VBModal,
  BCardText,
} from 'bootstrap-vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'

import {
  formatNumber,
  formatObject,
  downloadPDF,
  download,
} from '@/@core/utils/utils'
import {
  apiToastError,
  apiToastSuccess,
  apiToastWarning,
} from '@/@core/utils/toast'
import { showRelativeTime as _SRT } from '@/@core/utils/text'
import analytics from '@/@core/utils/analytics'
import constants, {
  DOWNLOAD_TYPES,
  FILE_DOWNLOAD_FORMATS,
  FILE_FORMATS,
  FILE_TYPES,
} from '@/constants'
import DownloadModal from '@/@core/components/popup-modal/DownloadModal.vue'
import DownloadCloudIcon from '@/assets/images/icons/download-cloud-icon.svg'
import ImportContentIcon from '@/assets/images/icons/import-content-icon.svg'
import ExportContentIcon from '@/assets/images/icons/export-content-icon.svg'
import SplitActionButton from '@/@core/components/split-action-button/SplitActionButton.vue'

import {
  CANCEL_ORDER,
  DOWNLOAD_ORDER,
  DOWNLOAD_ORDER_INVOICE,
  GET_ORDER,
  MODIFY_ORDER_PRODUCTS,
  ORDER_IMPORT,
  UPDATE_ORDER_PRODUCT_STATUS,
} from '@/store/modules/order.module'
import store from '@/store'
import { DOWNLOAD_SHIPMENT_INVOICE } from '@/store/modules/shipment.module'
import { DOWNLOAD_FILE, UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import { kpEndpoint, kpParams } from '@/axios'
import { mapState } from 'vuex'
import NotificationMixin from '@/views/mixins/NotificationMixin.vue'
import OrderProducts from './OrderProducts.vue'
import {
  enableShipmentFeature,
  formatProducts,
  getProductItem,
} from './order-utils'
import OrderActions from './OrderActions.vue'
import ShipmentAction from '../shipment/ShipmentAction.vue'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import OrderUploadInvoice from './OrderUploadInvoice.vue'
import OrderLogs from './OrderLogs.vue'
import OrderSummary from './OrderSummary.vue'
import OrderAddresses from './OrderAddresses.vue'
import OrderProductsFilter from './OrderProductsFilter.vue'

const { formattedDate, formatCurrency, getMonthAndYearFromDate } = utils

const {
  ORDER_STATUS,
  KP_DATE_FORMAT,
  ORDER_PRODUCT_SHIPMENT_STATUS,
  SHIPMENT_TEXT,
  BRAND_ACCESS,
} = c

const { DOWNLOAD_OPTIONS } = constants
const DOWNLOAD_MODAL_ID = 'download_modal'

export default {
  name: 'OrderDetail',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BImg,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormFile,
    BSpinner,
    OrderProducts,
    DownloadModal,
    SplitActionButton,
    OrderActions,
    ShipmentAction,
    OrderUploadInvoice,
    OrderLogs,
    OrderSummary,
    OrderAddresses,
    OrderProductsFilter,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixinVue, NotificationMixin],
  props: {
    shipmentOrder: {
      type: Object,
      default() {
        return null
      },
    },
    isShipping: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  emits: [
    'ship-now',
    'cancel-create-shipment',
    'create-shipment',
    'load-shipment',
  ],
  data() {
    return {
      isLoaded: false,
      order: null,
      orderData: null,
      totalUnits: null,
      total: null,
      orderUploadFile: null,
      isDownloading: false,
      DOWNLOAD_OPTIONS,
      orderApproving: false,
      downloadOption: {
        order: [
          FILE_DOWNLOAD_FORMATS.XLSX_STYLE,
          FILE_DOWNLOAD_FORMATS.XLSX_SKU,
        ],
      },
      DownloadCloudIcon,
      ExportContentIcon,
      ImportContentIcon,
      isModifyEnabled: false,
      discountInCredit: 0,
      totalPriceAfterDiscount: 0,
      modifiedOrderData: null,
      reloadkey: 1,
      modifyUsingImport: false,
      uploading: false,
      DOWNLOAD_MODAL_ID,
      filteredOrderProducts: [],
      isProductsFilterApplied: false,
      cancellingShipments: [],
      ORDER_STATUS,
      SHIPMENT_TEXT,
    }
  },
  computed: {
    orderId() {
      const id = this.order.shipmentId || this.order._id
      return id.substring(id.length - 4)
    },
    hasOrderConfirmed() {
      return this.order.status === ORDER_STATUS.CONFIRMED
    },
    canShowImportOrderContentBtn() {
      return this.order.isModifyEnabledBrand
    },
    canShowExportOrderContentBtn() {
      return this.order.isModifyEnabledBrand && !this.order.shipmentId
    },
    canShowDownloadOrderInvoiceBtn() {
      if (this.isSelfManagedOrder) {
        return this.order.shouldDisplayDownloadInvoice
      }
      return (
        (this.isRetailer || this.isBrand)
        && (this.order.shipmentId || this.hasOrderConfirmed)
      )
    },
    isSelfManagedOrder() {
      return this.order.isSelfManagedOrder
    },
    canShowOrderDropdownOptions() {
      return (
        this.canShowImportOrderContentBtn || this.canShowExportOrderContentBtn
      )
    },
    canShowDownloadOrderButton() {
      return (
        this.order.isDownloadEnabled
        || this.shipmentOrder
        || this.order.shipmentId
      )
    },
    isShipment() {
      return !!this.order.shipmentId
    },
    ...mapState({
      profileData: state => state.auth.profileData,
    }),
    isDirectBrand() {
      return (
        this.isBrand && this.profileData?.access?.includes(BRAND_ACCESS.DIRECT)
      )
    },
  },
  async mounted() {
    await this.loadOrder()
    if (!this.shipmentOrder) {
      this.downloadOption.order.push(FILE_DOWNLOAD_FORMATS.ZIP)
    }
    if (this.canShowDownloadOrderInvoiceBtn) {
      this.downloadOption.order.push(FILE_DOWNLOAD_FORMATS.PDF)
    }
    if (this.isShipment) {
      this.downloadOption.order.push(
        FILE_DOWNLOAD_FORMATS.SHIPMENT_DETAILS_PDF,
      )
    }
    if (!this.isShipment && this.order.status === ORDER_STATUS.CONFIRMED) {
      this.downloadOption.order.push(FILE_DOWNLOAD_FORMATS.ORDER_DETAILS_PDF)
    }
  },
  methods: {
    onPrintOrder() {
      this.isDownloading = true
      let payload = {}
      if (this.isShipment) {
        payload = {
          url: kpParams(
            kpEndpoint.shipment.printShipmentDetail,
            this.order._id,
          ),
          fileName: `shipment-detail-${this.order.shipmentSeqId}.pdf`,
          successMessage: `Your shipment-detail-${this.order.shipmentSeqId} is being downloaded...`,
          fileFormat: FILE_FORMATS.PDF,
        }
      } else {
        payload = {
          url: kpParams(kpEndpoint.order.printOrderDetail, this.order._id),
          fileName: `order-detail-${this.order.orderSeqId}.pdf`,
          successMessage: `Your order-detail-${this.order.orderSeqId} is being downloaded...`,
          fileFormat: FILE_FORMATS.PDF,
        }
      }
      store
        .dispatch(DOWNLOAD_FILE, payload)
        .catch(err => {
          apiToastError(err)
        })
        .finally(() => {
          this.isDownloading = false
          this.$bvModal.hide(DOWNLOAD_MODAL_ID)
        })
    },
    onAppliedFilter(isFilterApplied = false, products = []) {
      this.filteredOrderProducts = products
      this.isProductsFilterApplied = isFilterApplied
    },
    // validate expected availability date
    validateExAvDate(err) {
      if (
        this.isBrand
        && err.response.data
        && err.response.data.data
        && err.response.data.data.hasOwnProperty('isAvailableExAvDate')
        && !err.response.data.data.isAvailableExAvDate
      ) {
        this.$bvModal.show('modal-exp-av-date')
      }
    },

    /* ORDER ACTIONS */
    // update order
    updateOrder(shouldConfirmOrder = false) {
      const { payload, cancelledProductsCount } = formatProducts(
        this.order.products,
      )

      const params = {}
      if (shouldConfirmOrder) {
        params.shouldConfirmOrder = shouldConfirmOrder
      }

      store
        .dispatch(MODIFY_ORDER_PRODUCTS, {
          orderId: this.order._id,
          params,
          payload,
        })
        .then(res => {
          apiToastSuccess(res.data.message || 'Updated this order!')
          this.loadOrder()

          if (this.isBrand) {
            analytics.track(
              constants.TRACKS.ACTIONS.BRAND_MODIFIES_ORDER,
              formatObject({
                orderId: this.order.orderSeqId,
                collectionName: this.order.collectionName,
                noOfCancelledProducts: cancelledProductsCount,
                sumOfRemovedQuantities:
                  this.order.totalQuantity - this.totalUnits,
              }),
            )
          }
        })
        .catch(err => {
          apiToastWarning(err)
          this.validateExAvDate(err)
        })
    },

    // remove product from order
    removeOrderProduct(product, index) {
      const payload = {
        productIds: [product.productId],
        status: ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL,
      }
      store
        .dispatch(UPDATE_ORDER_PRODUCT_STATUS, {
          orderId: this.order._id,
          payload,
        })
        .then(res => {
          this.order.products[index].quantity = 0
          this.order.products[index].isDeletedByBrand = true
          if (this.isRetailer) {
            this.order.products[index].isDeletedByRetailer = true
          }
          this.loadOrder()
          apiToastSuccess(
            res.data.message || 'Removed product from this order!',
          )
        })
        .catch(err => apiToastWarning(err))
    },
    loadOrderData(order) {
      this.order = order
      this.orderData = JSON.parse(JSON.stringify(order))
      this.totalUnits = this.orderData.totalQuantity
      // update breadcrumb
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.map(item => {
        if (item.dynamic === true) {
          item.orderName = this.order.shipmentId
            ? order.shipmentSeqId
            : order.orderSeqId
        }
        return item
      })
      this.isLoaded = true
      // open popup for expectedAvailabilityDate after confirm order
      setTimeout(() => {
        if (
          order.shouldDisplayExpectedAvailabilityDatePopup
          && order.expectedAvailabilityDate == null
          && !this.isDirectBrand
        ) {
          this.$bvModal.show('modal-exp-av-date')
        }
      }, 1000)
      this.setDates(order)
    },
    setDates(order) {
      if (order.expectedAvailabilityDate && !order.isDataForCreateShipment) {
        this.order.expectedAvailabilityDate = getMonthAndYearFromDate(
          order.expectedAvailabilityDate,
        )
      }
    },
    /* load remote order */
    loadOrder() {
      this.isLoaded = false
      if (this.shipmentOrder) {
        return this.loadOrderData(this.shipmentOrder)
      } else {
        return store
          .dispatch(GET_ORDER, this.$route.params.orderId)
          .then(res => {
            const orderData = res.data.data
            enableShipmentFeature(orderData)
            this.loadOrderData(orderData)
            const pageTrack = this.isBrand
              ? constants.TRACKS.PAGES.BRAND_OPENS_ORDER_DETAILS
              : constants.TRACKS.PAGES.RETAILER_OPENS_ORDER_DETAILS
            analytics.track(pageTrack, { 'Order Id': orderData.orderSeqId })
          })
          .catch(err => {
            apiToastWarning(err)
          })
          .finally(() => {
            this.isLoaded = true
          })
      }
    },
    // relative time
    showRelativeTime(time) {
      return _SRT(time)
    },
    async downloadExcelFile(type) {
      if (this.isShipment) {
        this.downloadShipmentExcelFile(type)
        return
      }
      const params = {
        type,
      }
      const payload = {
        downloadType: DOWNLOAD_TYPES.ORDER_EXPORT.VALUE,
        respectiveId: this.order._id,
        fileType: FILE_FORMATS.XLSX,
      }
      this.isDownloading = true
      this.$store
        .dispatch(DOWNLOAD_ORDER, { payload, params })
        .then(res => {
          apiToastSuccess(res.data.message)
          this.$bvModal.hide(DOWNLOAD_MODAL_ID)
          this.notifyDownloadsNavMenu()
        })
        .catch(err => {
          apiToastError(err)
        })
        .finally(() => {
          this.isDownloading = false
        })
    },
    async downloadShipmentExcelFile(type) {
      this.isDownloading = true
      const params = {
        type,
      }
      const payload = {
        url: await this.$kpParams(
          this.$kpEndpoint.shipment.downloadProducts,
          this.order.shipmentId,
        ),
        fileName: `Shipment-Products-${this.shipmentSeqId}.xlsx`,
        successMessage: `Shipment-Products-${this.shipmentSeqId}.xlsx`,
        fileFormat: FILE_FORMATS.XLSX,
        params,
      }
      this.$store
        .dispatch(DOWNLOAD_FILE, payload)
        .then(() => {
          this.$bvModal.hide(DOWNLOAD_MODAL_ID)
        })
        .catch(err => {
          apiToastError(err)
        })
        .finally(() => {
          this.isDownloading = false
        })
    },
    async downloadZipFile() {
      this.isDownloading = true
      const payload = {
        downloadType: DOWNLOAD_TYPES.ORDER_EXPORT.VALUE,
        respectiveId: this.order._id,
        fileType: FILE_FORMATS.ZIP,
      }
      this.$store
        .dispatch(DOWNLOAD_ORDER, { payload })
        .then(res => {
          apiToastSuccess(res.data.message)
          analytics.track(
            constants.TRACKS.ACTIONS.DOWNLOAD_PRODUCT_IMAGES_ORDER,
            formatObject(this.order),
          )
          this.$bvModal.hide(DOWNLOAD_MODAL_ID)
          this.notifyDownloadsNavMenu()
        })
        .catch(err => {
          apiToastError(err)
        })
        .finally(() => {
          this.isDownloading = false
        })
    },
    // download order
    downloadOrderProducts(downloadType) {
      this.isDownloading = true
      switch (downloadType.value) {
        case FILE_DOWNLOAD_FORMATS.XLSX_STYLE.value:
        case FILE_DOWNLOAD_FORMATS.XLSX_SKU.value:
          this.downloadExcelFile(downloadType.value)
          break
        case FILE_DOWNLOAD_FORMATS.ORDER_DETAILS_PDF.value:
        case FILE_DOWNLOAD_FORMATS.SHIPMENT_DETAILS_PDF.value:
          this.onPrintOrder()
          break
        case FILE_DOWNLOAD_FORMATS.ZIP.value:
          this.downloadZipFile()
          break
        case FILE_DOWNLOAD_FORMATS.PDF.value:
          this.downloadOrderInvoice()
          break
        default:
          break
      }
      analytics.track(
        this.isBrand
          ? constants.TRACKS.ACTIONS.BRAND_DOWNLOADS_ORDER
          : constants.TRACKS.ACTIONS.RETAILER_DOWNLOADS_ORDER,
        formatObject({
          orderId: this.order.orderSeqId,
          exportFileFormat: downloadType,
        }),
      )
    },
    // Export Order Products
    async exportOrderProducts() {
      const payload = {
        url: await this.$kpParams(
          this.$kpEndpoint.order.downloadProducts,
          this.order._id,
        ),
        fileName: `Order-Products-${this.orderId}.xlsx`,
        successMessage: `Order-Products-${this.orderId}.xlsx`,
        fileFormat: FILE_FORMATS.XLSX,
      }
      store.dispatch(DOWNLOAD_FILE, payload)
    },
    async syncOrderProducts() {
      if (this.orderUploadFile) {
        this.uploading = true
        this.orderUploadFileLink = await store.dispatch(UPLOAD_DOCUMENTS, {
          fileType: FILE_TYPES.ORDER_UPLOAD,
          file: this.orderUploadFile,
        })
        const payload = {
          orderProductsFile: this.orderUploadFileLink,
        }
        store
          .dispatch(ORDER_IMPORT, { orderId: this.order._id, payload })
          .then(result => {
            this.orderUploadFile = null
            apiToastSuccess(result.data.message)
            this.importedPorducts(result.data.data)
            this.uploading = false
            this.$bvModal.hide('upload-Order')
          })
          .catch(err => {
            this.uploading = false
            apiToastWarning(err)
          })
      } else {
        apiToastWarning('Please Select a File')
      }
    },
    downloadOrderInvoice() {
      const endPoint = this.isShipment
        ? DOWNLOAD_SHIPMENT_INVOICE
        : DOWNLOAD_ORDER_INVOICE
      const seqId = this.isShipment
        ? this.order.shipmentSeqId
        : this.order.orderSeqId

      store
        .dispatch(endPoint, this.order._id)
        .then(async res => {
          if (
            this.order.shouldDisplayDownloadInvoice
            && this.order.invoiceUrl
            && !this.isShipment
          ) {
            download(
              this.order.invoiceUrl,
              `order-invoice-${this.order.orderSeqId}`,
            )
          } else {
            const reader = new FileReader()
            reader.readAsDataURL(res.data)
            reader.onloadend = () => {
              downloadPDF({
                pdf: reader.result,
                fileName: `${seqId}-Invoice`,
              })
            }
          }
          this.isDownloading = false
          this.$bvModal.hide(DOWNLOAD_MODAL_ID)
          apiToastSuccess(
            `Your Invoice is downloaded for the ${
              this.isShipment ? 'shipment' : 'order'
            } ${seqId}`,
          )
          const trackSubject = this.isRetailer
            ? constants.TRACKS.ACTIONS.RETAILER_DOWNLOADS_INVOICE
            : constants.TRACKS.ACTIONS.BRAND_DOWNLOADS_INVOICE
          analytics.track(trackSubject)
        })
        .catch(err => {
          this.isDownloading = false
          apiToastWarning(err)
        })
    },
    enableModifyProducts() {
      this.isModifyEnabled = true
    },
    revertModifiedChanges() {
      this.order.products.forEach((product, index) => {
        product.items = getProductItem(this.orderData.products[index].items)
        if (product.isCarton) {
          product.quantity = this.orderData.products[index].quantity
        }
      })
      this.modifyUsingImport = false
      this.isModifyEnabled = false
      this.totalUnits = this.orderData.totalQuantity
      this.reloadkey++
    },
    importedPorducts(products) {
      this.modifyUsingImport = true
      this.order.products.forEach((product, index) => {
        product.items = getProductItem(this.orderData.products[index].items)
      })
      products.forEach(product => {
        if (product.productId) {
          const index = this.order.products.findIndex(
            p => p.productId === product.productId,
          )
          if (index >= 0) {
            if (this.order.products[index].isCarton) {
              this.order.products[index].quantity = product.quantity
            } else {
              this.order.products[index].items = getProductItem(
                this.orderData.products[index].items,
                product.items,
              )
            }
          }
        }
      })
      this.reloadkey++
    },

    cancelOrderByBrand(isConfirmed = false) {
      const cancelOrder = params => {
        store
          .dispatch(CANCEL_ORDER, params)
          .then(res => {
            apiToastSuccess(res.data.message || 'Order cancelled successfully')
            this.loadOrder()
          })
          .catch(err => {
            if (err.response.status === 425) {
              this.cancellingShipments = err.response.data.data.cancellingShipments
              this.$bvModal.show('brand-cancel-order')
            } else {
              apiToastWarning(err)
            }
          })
      }

      if (!isConfirmed) {
        cancelOrder({ orderId: this.$route.params.orderId })
      } else {
        cancelOrder({
          orderId: this.$route.params.orderId,
          params: { shouldProceed: true },
        })
      }
    },
  },
  setup() {
    return {
      formatNumber,
      formattedDate,
      formatCurrency,
      KP_DATE_FORMAT,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-order-detail.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.order-products-filter {
  position: sticky;
  top: $nav-bar-height;
  z-index: 50;
  background-color: $body-bg;
}
</style>
