var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoaded)?_c('b-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-2 px-1"},[_c('div',{staticClass:"d-flex w-100 align-items-start flex-wrap"},[_c('div',{staticClass:"align-self-start d-flex flex-row"},[_c('div',{staticClass:"header-right-label d-flex align-items-center mr-2"},[(_vm.order.shipmentSeqId)?_c('b-card-title',{staticClass:"m-0"},[_vm._v(" Shipment ID: "+_vm._s(_vm.order.shipmentSeqId)+" ")]):_c('b-card-title',{staticClass:"m-0"},[_vm._v(" Order ID: "+_vm._s(_vm.order.orderSeqId)+" ")])],1),_c('div',{staticClass:"header-left-actions d-flex"},[(!_vm.isShipment)?_c('split-action-button',{attrs:{"label":"Download Order","icon":_vm.DownloadCloudIcon,"disable-button":!_vm.canShowDownloadOrderButton,"hide-dropdown-options":!_vm.canShowOrderDropdownOptions,"dropdown-menu-width":"223px"},on:{"click":function($event){return _vm.$bvModal.show(_vm.DOWNLOAD_MODAL_ID)}}},[(_vm.canShowImportOrderContentBtn)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$bvModal.show('upload-Order')}}},[_c('b-img',{staticClass:"btn-svg-icon",attrs:{"src":_vm.ImportContentIcon,"alt":"import content"}}),_vm._v(" Import Content ")],1):_vm._e(),(_vm.canShowExportOrderContentBtn)?_c('b-dropdown-item',{on:{"click":_vm.exportOrderProducts}},[_c('b-img',{staticClass:"btn-svg-icon",attrs:{"src":_vm.ExportContentIcon,"alt":"Export content"}}),_vm._v(" Export Content ")],1):_vm._e()],1):_vm._e(),(_vm.isShipment)?_c('shipment-action',{attrs:{"shipment":_vm.order},on:{"load-shipment":function($event){return _vm.$emit('load-shipment')}}}):_vm._e(),(
                _vm.hasOrderConfirmed && !_vm.canShowOrderDropdownOptions && _vm.isBrand
              )?_c('order-upload-invoice',{staticClass:"ml-1",attrs:{"order":_vm.order,"load-order":_vm.loadOrder}}):_vm._e()],1)]),_c('div',{staticClass:"ml-auto"},[(
              _vm.order.status === _vm.ORDER_STATUS.CONFIRMED &&
                _vm.order.isSelfManagedOrder &&
                !_vm.shipmentOrder &&
                _vm.isBrand
            )?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.cancelOrderByBrand(false)}}},[_vm._v(" Cancel order ")]):_c('order-actions',{attrs:{"order":_vm.order,"original-order":_vm.orderData,"total-units":_vm.totalUnits,"modify-using-import":_vm.modifyUsingImport,"is-direct-brand":_vm.isDirectBrand,"load-order":_vm.loadOrder},on:{"enable-modify-changes":_vm.enableModifyProducts,"revert-modified-changes":_vm.revertModifiedChanges,"update-order":_vm.updateOrder}})],1),(_vm.shipmentOrder && !_vm.order.shipmentId)?_c('div',{staticClass:"ml-auto"},[_c('b-button',{staticClass:"mr-1 font-weight-bolder",attrs:{"variant":"outline-info"},on:{"click":function($event){return _vm.$emit('cancel-create-shipment')}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"ml-1 font-weight-bolder",attrs:{"variant":"info","disabled":_vm.isShipping},on:{"click":function($event){return _vm.$emit('create-shipment')}}},[_vm._v(" "+_vm._s(_vm.isShipping ? 'Loading...' : 'Create Shipment')+" ")])],1):_vm._e()])]),_c('b-row',{staticClass:"p-0 match-height order-detail"},[_c('b-col',{attrs:{"lg":"4"}},[_c('order-logs',{attrs:{"order":_vm.order}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('order-summary',{attrs:{"order":_vm.order},on:{"load-order":_vm.loadOrder}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('order-addresses',{attrs:{"order":_vm.order}})],1)],1),(_vm.order.products && _vm.order.products.length)?_c('b-row',{staticClass:"mb-1 order-products-filter"},[_c('b-col',{attrs:{"cols":"12"}},[_c('order-products-filter',{attrs:{"order-products":_vm.order.products},on:{"on-apply-filter":_vm.onAppliedFilter}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"product-checkout mt-0 list-view d-block"},[_c('b-row',[_c('order-products',{key:_vm.reloadkey,staticClass:"col-md-12",attrs:{"order":_vm.order,"order-products":_vm.isProductsFilterApplied
                  ? _vm.filteredOrderProducts
                  : _vm.order.products,"is-shipment":_vm.shipmentOrder ? true : false,"is-modify-enabled":_vm.isModifyEnabled,"is-filter-applied":_vm.isProductsFilterApplied},on:{"remove-product":_vm.removeOrderProduct,"ship-now":function($event){return _vm.$emit('ship-now', $event, _vm.orderData.products.length)}}})],1)],1)])],1),_c('b-modal',{attrs:{"id":"upload-Order","centered":"","title":"Upload Order","hide-footer":""}},[_c('div',[_c('b-form',[_c('b-form-group',{attrs:{"label":"Upload the Order products file","label-for":"import-Order"}},[_c('b-form-file',{attrs:{"id":"import-Order","accept":".xlsx","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.orderUploadFile),callback:function ($$v) {_vm.orderUploadFile=$$v},expression:"orderUploadFile"}})],1),_c('b-button',{staticClass:"float-right mt-2 mt-1",attrs:{"variant":"primary","disabled":_vm.uploading},on:{"click":_vm.syncOrderProducts}},[_vm._v(" "+_vm._s(_vm.uploading ? 'Uploading...' : 'Upload')+" ")])],1)],1)]),(_vm.isBrand)?_c('b-modal',{attrs:{"id":"brand-cancel-order","ok-variant":"danger","ok-title":"Confirm","variant":"danger","cancel-title":"Cancel","modal-class":"modal-danger","centered":"","title":"Cancel order"},on:{"ok":function($event){return _vm.cancelOrderByBrand(true)}}},[_c('b-card-text',[_vm._v(" This order contains shipments. ")]),(_vm.cancellingShipments)?_c('ul',_vm._l((_vm.cancellingShipments),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item.shipmentSeqId)+" - "+_vm._s(_vm.SHIPMENT_TEXT[item.status])+" ")])}),0):_vm._e(),_c('b-card-text',[_vm._v(" Proceed with the cancellation? ")])],1):_vm._e(),_c('div',[_c('download-modal',{attrs:{"modal-id":_vm.DOWNLOAD_MODAL_ID,"options":_vm.downloadOption.order,"is-downloading":_vm.isDownloading},on:{"on-click-download":_vm.downloadOrderProducts}})],1)],1):[_c('b-card',{staticClass:"text-center p-4"},[_c('b-spinner')],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }