import { getVariants, hasDifferences } from '@/@core/utils/utils'
import { flattenItems, formatProducts } from '../order-utils'

export const hasBeenChanged = (products, originalProducts) => {
  if (!products || !originalProducts) {
    return false
  }

  // Compare product IDs directly
  const productIds = products.map(product => product.productId)
  const originalProductIds = originalProducts.map(
    product => product.productId,
  )

  if (
    productIds.length !== originalProductIds.length
    || !productIds.every(id => originalProductIds.includes(id))
  ) {
    return true
  }

  // Function to process products by adding variants
  const processProducts = productsList => productsList.map(product => {
      if (product.items && product.items.length > 0) {
        const { variants } = getVariants(product.items)
        return {
          ...product,
          variants,
        }
      } else {
        return product
      }
    })

  const processedProducts = processProducts(products)
  const processedOriginalProducts = processProducts(originalProducts)

  // Format the products
  const { payload } = formatProducts(processedProducts)
  const { payload: originalProductsFormatted } = formatProducts(
    processedOriginalProducts,
  )

  // Flatten the formatted products
  const flattenedPayload = flattenItems(payload)
  const flattenedOriginalProducts = flattenItems(originalProductsFormatted)

  console.log('flattenedPayload', flattenedPayload)

  // Compare the flattened products
  const hasDiff = hasDifferences({
    array1: flattenedOriginalProducts,
    array2: flattenedPayload,
    keySelector: item => (item.skuId !== null ? item.skuId : item.productId),
    valueSelector: item => item.value,
  })

  return hasDiff
}
