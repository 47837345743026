<template>
  <div v-if="isOrderActionEnabled">
    <b-button
      v-if="order.shouldAddProducts && isBrand"
      variant="info"
      class="font-weight-bolder mr-1"
      @click="$bvModal.show(addProductModalId)"
    >
      Add Products
    </b-button>
    <b-button
      v-if="isModifyButtonEnabled"
      variant="info"
      class="font-weight-bolder mr-1"
      @click="enableModify(false)"
    >
      {{ isModify ? 'Revert changes' : 'Modify' }}
    </b-button>
    <b-dropdown
      :text="selectedAction.text"
      variant="none"
      class="order-action"
      split
      :disabled="isModify && hasError"
      right
      @click="openActionModal(selectedAction.action)"
    >
      <b-dropdown-item
        v-if="isApproveEnabled && !isModify"
        @click="openActionModal(ORDER_STATUS.CONFIRMED)"
      >
        Approve
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isModifyButtonEnabled && isModify"
        @click="openActionModal(MODIFY)"
      >
        Modify Order
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isModifyButtonEnabled && isModify"
        @click="openActionModal(MODIFY_AND_CONFIRM)"
      >
        Modify & Confirm Order
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isCancelEnabled"
        @click="openActionModal(ORDER_STATUS.CANCELLED)"
      >
        Cancel
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isRejectEnabled && !isModify"
        @click="openActionModal(ORDER_STATUS.REJECTED)"
      >
        Reject
      </b-dropdown-item>
    </b-dropdown>
    <b-modal
      id="modal-date"
      ref="expAvailability"
      hide-header
      hide-header-close
      hide-footer
      no-close-on-backdrop
      centered
    >
      <div class="pb-5-px border-bottom">
        <span
          class="custom-modal-heading color-dark-black"
        >Please select estimated delivery window</span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="$refs.expAvailability.hide()"
        />
      </div>
      <div class="mt-1">
        <div class="d-inline-flex align-items-center position-relative">
          <span class="color-dark-black font-weight-bold mr-1">
            Estimated delivery Window:
          </span>
          <flat-pickr
            ref="expDate"
            v-model="expectedDate"
            class="form-control mt-0 exp-av-date"
            :config="VUE_FLATPICKR_MONTH_CONFIG"
            :placeholder="expectedDate || KP_MONTH_FORMAT.PLACEHOLDER"
          />
          <feather-icon
            icon="CalendarIcon"
            size="20"
            class="position-absolute position-right-0 mr-6-px"
            @click="toggleDatePicker"
          />
        </div>
      </div>
      <div class="text-center my-1">
        <b-button
          type="submit"
          variant="info"
          class="mt-1 mr-2 px-sm-3 font-weight-bolder"
          :disabled="loading || !expectedDate"
          @click="onClickSaveDate"
        >
          {{ loading ? 'Saving' : 'Save' }}
        </b-button>
        <b-button
          type="reset"
          class="mt-1 px-sm-3 font-weight-bolder"
          variant="outline-info"
          @click="$refs.expAvailability.hide()"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-approve"
      ref="actionModal"
      hide-header
      hide-header-close
      hide-footer
      modal-class="modal-approve"
      centered
    >
      <div class="pb-5-px border-bottom">
        <span class="custom-modal-heading color-dark-black">{{
          modalTitle
        }}</span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="$refs.actionModal.hide()"
        />
      </div>
      <div class="mt-1">
        <b-card-text class="color-dark-black font-weight-bold">
          {{ modalBody }}
        </b-card-text>
      </div>
      <div class="text-center my-1">
        <b-button
          type="submit"
          variant="info"
          class="mt-1 mr-2 px-sm-3 font-weight-bolder"
          :disabled="loading"
          @click="confirmAction(action)"
        >
          {{ loading ? 'Confirming' : 'Confirm' }}
        </b-button>
        <b-button
          type="reset"
          class="mt-1 px-sm-3 font-weight-bolder"
          variant="outline-info"
          @click="$refs.actionModal.hide()"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>
    <b-modal
      :id="addProductModalId"
      size="xl"
      hide-header
      hide-header-close
      hide-footer
      no-close-on-backdrop
      centered
    >
      <div class="py-1 border-bottom">
        <span
          class="custom-modal-heading color-dark-black"
        >Add Products Window</span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="$bvModal.hide(addProductModalId)"
        />
      </div>
      <div>
        <add-order-products
          :collection-id="order.collectionId"
          :order="order"
          :add-product-modal-id="addProductModalId"
          :load-order="loadOrder"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  BCardText,
} from 'bootstrap-vue'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import flatPickr from 'vue-flatpickr-component'

import {
  APPROVE_ORDER,
  CANCEL_ORDER,
  REJECT_ORDER,
  UPDATE_ORDER,
} from '@/store/modules/order.module'
import store from '@/store'
import analytics from '@/@core/utils/analytics'
import constants, {
  MONTH_START_DATE,
  VUE_FLATPICKR_MONTH_CONFIG,
} from '@/constants'
import { formatObject } from '@/@core/utils/utils'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import AddOrderProducts from './AddOrderProducts.vue'
import { hasBeenChanged } from './utils/hasBeenChanged'

const { formattedDate } = utils

const { ORDER_STATUS, KP_DATE_FORMAT, KP_MONTH_FORMAT } = c

const addProductModalId = 'add_products_modal'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BCardText,
    FeatherIcon,
    flatPickr,
    AddOrderProducts,
  },
  mixins: [UserRoleMixinVue],
  props: {
    order: {
      type: Object,
      default() {
        return null
      },
    },
    totalUnits: {
      type: Number,
      default() {
        return 0
      },
    },
    modifyUsingImport: {
      type: Boolean,
      default: false,
    },
    loadOrder: {
      type: Function,
      default() {},
    },
    isDirectBrand: {
      type: Boolean,
      default: false,
    },
    originalOrder: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loading: false,
      isModify: false,
      selectedAction: {
        text: '',
        action: '',
      },
      ORDER_STATUS,
      modalTitle: '',
      modalBody: '',
      MODIFY: 'modify',
      MODIFY_AND_CONFIRM: 'modify_and_confirm',
      action: '',
      expectedDate: null,
      KP_DATE_FORMAT,
      hasError: false,
      VUE_FLATPICKR_MONTH_CONFIG,
      KP_MONTH_FORMAT,
      addProductModalId,
    }
  },
  computed: {
    isCancelEnabled() {
      const cancelIncludesStatus = [
        ORDER_STATUS.PENDING_RETAILER,
        ORDER_STATUS.NEW,
      ]
      return (
        this.isRetailer && cancelIncludesStatus.includes(this.order.status)
      )
    },
    isRejectEnabled() {
      return this.isBrand && this.order.isRejectEnabled
    },
    isModifyButtonEnabled() {
      return this.isBrand && this.order.status === ORDER_STATUS.ACCEPTED
    },
    isApproveEnabled() {
      return (
        (this.isBrand && this.order.status === ORDER_STATUS.ACCEPTED)
        || (this.isRetailer && this.order.status === ORDER_STATUS.PENDING_RETAILER)
      )
    },
    isOrderActionEnabled() {
      return this.isApproveEnabled || this.isCancelEnabled
    },
    getCurrentDate() {
      return formattedDate(new Date())
    },
  },
  watch: {
    modifyUsingImport(newValue) {
      if (newValue) {
        this.enableModify(newValue)
      }
    },
    order: {
      handler(orderData) {
        this.hasError = orderData.products.some(p => p.hasError)
      },
      deep: true,
    },
  },
  emits: ['enable-modify-changes', 'update-order', 'revert-modified-changes'],
  mounted() {
    // Setting Up default Action button
    if (this.isApproveEnabled) {
      this.selectedAction = {
        text: 'Approve',
        action: ORDER_STATUS.CONFIRMED,
      }
    } else {
      if (this.isCancelEnabled) {
        this.selectedAction = {
          text: 'Cancel',
          action: ORDER_STATUS.CANCELLED,
        }
      }
      if (this.isRejectEnabled) {
        this.selectedAction = {
          text: 'Reject',
          action: ORDER_STATUS.REJECTED,
        }
      }
    }
    if (this.order.expectedAvailabilityDate) {
      this.expectedDate = this.order.expectedAvailabilityDate
    }
  },
  methods: {
    toggleDatePicker() {
      this.$refs.expDate.$el._flatpickr.toggle()
    },
    openActionModal(action) {
      switch (action) {
        case ORDER_STATUS.CONFIRMED: {
          this.modalTitle = 'Order Confirmation'
          this.modalBody = 'Are you sure you want to approve the order?'
          this.action = ORDER_STATUS.CONFIRMED
          this.$refs.actionModal.show()
          break
        }
        case ORDER_STATUS.CANCELLED: {
          this.modalTitle = 'Order Cancel'
          this.modalBody = 'Are you sure you want to cancel the order?'
          this.action = ORDER_STATUS.CANCELLED
          this.$refs.actionModal.show()
          break
        }
        case ORDER_STATUS.REJECTED: {
          this.modalTitle = 'Order Reject'
          this.modalBody = 'Are you sure you want to reject the order?'
          this.action = ORDER_STATUS.REJECTED
          this.$refs.actionModal.show()
          break
        }
        case this.MODIFY: {
          if (!this.isModify) {
            apiToastWarning(
              'Please click on modify to change product quantities',
            )
          } else if (!hasBeenChanged(this.order.products, this.originalOrder.products)) {
            apiToastWarning('Please modify the quantities before confirm')
          } else {
            this.modalTitle = 'Modify Order'
            this.modalBody = 'Are you sure you want to modify the product quantities?'
            this.action = this.MODIFY
            this.$refs.actionModal.show()
          }
          break
        }
        case this.MODIFY_AND_CONFIRM: {
          if (!this.isModify) {
            apiToastWarning(
              'Please click on modify to change product quantities',
            )
          } else if (!hasBeenChanged(this.order.products, this.originalOrder.products)) {
            apiToastWarning('Please modify the quantities before confirm')
          } else {
            this.modalTitle = 'Modify Order & Confirm'
            this.modalBody = 'Are you sure you want to modify the product quantities and confirm the order?'
            this.action = this.MODIFY_AND_CONFIRM
            this.$refs.actionModal.show()
          }
          break
        }
        default:
          break
      }
    },
    validateExAvDate(err) {
      if (
        this.isBrand
        && err.response.data
        && err.response.data.data
        && err.response.data.data.hasOwnProperty('isAvailableExAvDate')
        && !err.response.data.data.isAvailableExAvDate
      ) {
        this.$refs.actionModal.hide()
        this.$refs.expAvailability.show()
        setTimeout(() => {
          this.toggleDatePicker()
        }, 400)
      }
    },
    approveOrder() {
      if (this.isBrand) {
        // applied validation for brand
        if (this.order.totalQuantity !== this.totalUnits) {
          apiToastWarning(
            'You have some new changes. Please click modify before approve',
          )
          return
        }
        if (!this.order.expectedAvailabilityDate && !this.isDirectBrand) {
          apiToastWarning('Please Enter Estimated Delivery Window')
          this.$refs.actionModal.hide()
          this.$refs.expAvailability.show()
          setTimeout(() => {
            this.toggleDatePicker()
          }, 400)
          return
        }
      }
      this.approveOrderData()
    },
    // the function called after validation
    approveOrderData() {
      this.loading = true
      store
        .dispatch(APPROVE_ORDER, this.$route.params.orderId)
        .then(res => {
          this.loading = false
          apiToastSuccess(res.data.message || 'Approved this order!')
          this.$refs.actionModal.hide()
          this.loadOrder()
        })
        .catch(err => {
          this.loading = false
          apiToastWarning(err)
          this.validateExAvDate(err)
        })
    },
    rejectOrder() {
      if (this.isRetailer) {
        apiToastWarning("You don't have permission!")
      } else {
        this.$kpApi
        store
          .dispatch(REJECT_ORDER, this.$route.params.orderId)
          .then(res => {
            apiToastSuccess(res.data.message || 'Rejected this order!')
            this.loadOrder()
          })
          .catch(err => apiToastWarning(err))
      }
    },
    cancelOrder() {
      if (this.isBrand) {
        apiToastWarning("You don't have permission!")
      } else {
        store
          .dispatch(CANCEL_ORDER, { orderId: this.$route.params.orderId })
          .then(res => {
            apiToastSuccess(res.data.message || 'Cancelled this order!')
            this.loadOrder()
          })
          .catch(err => apiToastWarning(err))
      }
    },
    trackAction(action) {
      if (action !== this.MODIFY) {
        analytics.track(
          this.isBrand
            ? constants.TRACKS.ACTIONS.BRAND_CHANGES_ORDER_STATUS
            : constants.TRACKS.ACTIONS.RETAILER_CHANGES_ORDER_STATUS,
          formatObject({
            orderId: this.order.orderSeqId,
            collectionName: this.order.collectionName,
            selectedStatus: action,
          }),
        )
      }
    },
    confirmAction(action) {
      switch (action) {
        case ORDER_STATUS.CONFIRMED: {
          this.approveOrder()
          break
        }
        case ORDER_STATUS.CANCELLED: {
          this.cancelOrder()
          break
        }
        case ORDER_STATUS.REJECTED: {
          this.rejectOrder()
          break
        }
        case this.MODIFY: {
          this.$emit('update-order')
          break
        }
        case this.MODIFY_AND_CONFIRM: {
          if (!this.order?.expectedAvailabilityDate) {
            apiToastWarning('Please Enter Estimated Delivery Window')
            this.$refs.actionModal.hide()
            this.$refs.expAvailability.show()
            setTimeout(() => {
              this.toggleDatePicker()
            }, 400)
            return
          }
          this.$emit('update-order', true)
          break
        }
        default: {
          break
        }
      }
      this.trackAction(action)
    },
    enableModify(enable = false) {
      this.isModify = !this.isModify
      if (enable) {
        this.isModify = true
      }
      if (this.isModify) {
        this.$emit('enable-modify-changes')
        this.selectedAction = {
          text: 'Modify Order',
          action: this.MODIFY,
        }
      } else {
        this.selectedAction = {
          text: 'Approve',
          action: ORDER_STATUS.CONFIRMED,
        }
        this.$emit('revert-modified-changes')
      }
    },
    async onClickSaveDate() {
      if (!this.expectedDate) {
        apiToastWarning('Please select the date')
        return
      }
      const expectedAvailabilityDate = `${MONTH_START_DATE}${this.expectedDate}`
      this.loading = true
      const payload = {
        expectedAvailabilityDate,
      }
      await store.dispatch(UPDATE_ORDER, { orderId: this.order._id, payload })
      this.order.expectedAvailabilityDate = this.expectedDate
      if (this.action === ORDER_STATUS.CONFIRMED) {
        this.approveOrderData()
      } else {
        this.$emit('update-order', true)
      }
    },
  },
}
</script>
